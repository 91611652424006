import React, { useState } from "react";
import TableHeader, {
  TableHeadProps,
} from "../../../../components/common/Table/TableHeader";

interface SolventMixType {
  S1: string;
  S1P: number;
  S2: string;
  S2P: number;
  S3: string;
  S3P: number;
}

interface EditableTableProps {
  rows: SolventMixType[];
  setRows: (rows: SolventMixType[]) => void;
}

const EditableTable = ({ rows, setRows }: EditableTableProps) => {
  const tableHeaders: TableHeadProps[] = [
    {
      title: "Solvent 1",
      fieldName: "S1",
    },
    {
      title: "Composition 1",
      fieldName: "S1P",
    },
    {
      title: "Solvent 2",
      fieldName: "S2",
    },
    {
      title: "Composition 2",
      fieldName: "S2P",
    },
    {
      title: "Solvent 3",
      fieldName: "S3",
    },
    {
      title: "Composition 3",
      fieldName: "S3P",
    },
    {
      title: "Operate",
    },
  ];

  const handleDeleteRow = (index: any) => {
    const updatedRows = rows?.filter((row, i) => i !== index);
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { S1: "", S1P: 0, S2: "", S2P: 0, S3: "", S3P: 0 }]);
  };

  const handleInputChange = (index: any, event: any) => {
    console.log(event.target.name, "tg", event.target.value);
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [event.target.name]: event.target.value } : row
    );
    console.log(updatedRows, "updatedRows");
    setRows(updatedRows);
  };

  return (
    <div
      className="container mx-auto p-4 pt-0"
      style={{
        maxHeight: "60vh",
        overflowY: "scroll",
      }}
    >
      <div className="table-outer">
        <div className="table-responsive theme-table">
          <table className="table">
            <TableHeader heads={tableHeaders} />
            <tbody>
              {rows.map((row: SolventMixType, index) => (
                <tr key={index} className="border-b">
                  <td className="px-2 py-2 justify-center">
                    <div className="input-wrap W-32">
                      <input
                        // type="number"
                        name="S1"
                        value={row.S1}
                        onChange={(e) => handleInputChange(index, e)}
                        className="theme-ip w-full"
                      />
                    </div>
                  </td>
                  <td className="px-2 py-2 justify-center">
                    <div className="input-wrap w-32">
                      <input
                        type="number"
                        name="S1P"
                        value={row.S1P}
                        onChange={(e) => handleInputChange(index, e)}
                        className="theme-ip w-full"
                      />
                    </div>
                  </td>
                  <td className="px-2 py-2 justify-center">
                    <div className="input-wrap w-32">
                      <input
                        // type="number"
                        name="S2"
                        value={row.S2}
                        onChange={(e) => handleInputChange(index, e)}
                        className="theme-ip w-full"
                      />
                    </div>
                  </td>
                  <td className="px-2 py-2 justify-center">
                    <div className="input-wrap w-32">
                      <input
                        type="number"
                        name="S2P"
                        value={row.S2P}
                        onChange={(e) => handleInputChange(index, e)}
                        className="theme-ip w-full"
                      />
                    </div>
                  </td>
                  <td className="px-2 py-2 justify-center">
                    <div className="input-wrap w-32">
                      <input
                        // type="number"
                        name="S3"
                        value={row.S3}
                        onChange={(e) => handleInputChange(index, e)}
                        className="theme-ip w-full"
                      />
                    </div>
                  </td>
                  <td className="px-2 py-2 justify-center">
                    <div className="input-wrap w-32">
                      <input
                        type="number"
                        name="S3P"
                        value={row.S3P}
                        onChange={(e) => handleInputChange(index, e)}
                        className="theme-ip w-full"
                      />
                    </div>
                  </td>
                  <td className="px-2 py-2 justify-center text-center">
                    <button
                      onClick={() => handleDeleteRow(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={handleAddRow}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          + Add mixture
        </button>
      </div>
    </div>
  );
};

export default EditableTable;
