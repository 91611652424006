import React, { useEffect, useState } from "react";
import { Icon, Modal } from "semantic-ui-react";
import EditableTable from "./EditableTable";
import * as XLSX from "xlsx";

interface SolventMixType {
  S1: string;
  S1P: number;
  S2: string;
  S2P: number;
  S3: string;
  S3P: number;
  imported?: boolean;
}

const rowInitialValue: SolventMixType[] = [
  { S1: "", S1P: 0, S2: "", S2P: 0, S3: "", S3P: 0 },
];

interface SolventListModalProps {
  isModalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  handleSolventData: (rows:SolventMixType[]) => void;
}

const SolventListModal = ({
  isModalOpen,
  toggleModal,
  handleSolventData
}: SolventListModalProps) => {
  const [rows, setRows] = useState<SolventMixType[]>(rowInitialValue);

  const handleImportAllSolvents = async (e: any) => {
    const shouldImportAll = e.target.checked;

    if (shouldImportAll) {
      try {
        const response = await fetch("/data/SolventList.xlsx");
        const arrayBuffer = await response.arrayBuffer();

        // Read workbook
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Get first sheet
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON row-wise
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Transform to row-wise JSON format
        const headers: any = jsonData[0]; // First row contains column names
        const rowWiseData = jsonData.slice(1).map((row: any) => {
          let rowObject: any = {};
          headers.forEach((header: string, index: number) => {
            rowObject[header] = row[index] || ""; // Assign each column value to its respective header
          });
          return rowObject;
        });

        const formattedData: SolventMixType[] =
          rowWiseData?.length > 0
            ? rowWiseData?.map((row) => {
                return {
                  S1: row["Solvent 1"],
                  S1P: row["Composition 1"],
                  S2: row["Solvent 2"],
                  S2P: row["Composition 2"],
                  S3: row["Solvent 3"],
                  S3P: row["Composition 3"],
                };
              })
            : rowInitialValue;

        setRows(formattedData.map((r) => ({ ...r, imported: true })));
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    } else {
      // If checkbox is unchecked, keep only manually added fields
      setRows((prevRows) => prevRows.filter((row) => !row.imported));
    }
  };

  const handleCloseModal = () => {
    setRows(rowInitialValue);
    toggleModal(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      size="large"
      dimmer="blurring"
      className="rounded-lg shadow-sm"
    >
      <Modal.Header align="center" className="border-none pb-10">
        <Icon
          name="times circle outline"
          color="grey"
          className="close-icon "
          onClick={handleCloseModal}
        ></Icon>
      </Modal.Header>
      <Modal.Content className="audit-modal">
        <div className="gap-4 p-4 mb-6 p-4">
          <h3>Create a new Solvent System</h3>
          <div className="w-full md-4 p-4 pb-6">
            <div className="flex flex-col space-y-4">
              <label className="flex items-center space-x-2">
                <span className="form-label">Import all solvents</span>
                <input
                  type="checkbox"
                  className="form-checkbox  w-6 h-6"
                  // checked={checkedPureSolvents}
                  onChange={handleImportAllSolvents}
                />
              </label>
            </div>
          </div>
          <div className="flex flex-col space-y-4 gap-4 p-4">
            <h4 className="text-black-500">Note: <span className="form-label">All compositions are in volume fraction (at 25 °C)</span></h4>
          </div>
          <EditableTable rows={rows} setRows={setRows} />
          <div className="w-full flex flex-row-reverse ">
            <div className="w-1/6 m-4 mr-2">
              <button
                type="button"
                className="bg-theme text-white py-2 px-4 rounded-lg text-lg w-full"
                onClick={() => handleSolventData(rows)}
              >
                Submit Solvent List
              </button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SolventListModal;
