import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits, setTitleNumberInput } from "../../../../../utils/common";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import { useEffect } from "react";

const PSD = (props: any) => {
  const { control } = useForm<any>({ defaultValues: props.initialData });
  const methods = useFormContext();
  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
  }, []);

  return (
    <>
      <div className="row">

      {!props.readable || control?._formValues?.psd10 ? (
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="psd10">
              <span className="form-label">PSD 10 (mic)</span>
              {!props.readable ? (
                <Controller
                  name="psd.psd10"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.psd?.psd10 ?? ""}
                  render={({ field }) => <input type="number"
                                                data-cy="psd_psd10"
                                                onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.psd10}</p>
              )}
            </label>
          </div>
        </div>
      ) : null }

      {!props.readable || control?._formValues?.psd50 ? (
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="psd50">
              <span className="form-label">PSD 50 (mic)</span>
              {!props.readable ? (
                <Controller
                  name="psd.psd50"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.psd?.psd50 ?? ""}
                  render={({ field }) => <input type="number"
                                                data-cy="psd_psd50"
                                                onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.psd50}</p>
              )}
            </label>
          </div>
        </div>
      ) : null }

      {!props.readable || control?._formValues?.psd90 ? (
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="psd90">
              <span className="form-label">PSD 90 (mic)</span>
              {!props.readable ? (
                <Controller
                  name="psd.psd90"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.psd?.psd90 ?? ""}
                  render={({ field }) => <input type="number"
                                                data-cy="psd_psd90"
                                                onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.psd90}</p>
              )}
            </label>
          </div>
        </div>
      ) : null }

      {!props.readable || control?._formValues?.eln_number ? (
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="eln_number">
              <span className="form-label">ELN Number</span>
              {!props.readable ? (
                <Controller
                  name="psd.eln_number"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.psd?.eln_number ?? ""}
                  render={({ field }) => <input
                    data-cy="psd_eln_number"
                    className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number}</p>
              )}
            </label>
          </div>
        </div>
      ) : null }

      {!props.readable || control?._formValues?.method_name ? (
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label className="ip-wrap" htmlFor="method_name">
              <span className="form-label">Method Name/#</span>
              {!props.readable ? (
                <Controller
                  name="psd.method_name"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.psd?.method_name ?? ""}
                  render={({ field }) => <input
                    data-cy="psd_method_name"
                    className="theme-ip" {...field} />}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">{control?._formValues?.method_name}</p>
              )}
            </label>
          </div>
        </div>
      ) : null }

      {!props.readable || control?._formValues?.regulatory_filing ? (
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label
              className="ip-wrap"
              htmlFor="regulatory_filing"
            >
                            <span className="form-label">
                                Regulatory Filing
                            </span>
              <div className="input-wrap select-outer">
                {!props.readable ? (
                  <Controller
                    name="psd.regulatory_filing"
                    control={methods.control}
                    defaultValue={methods.control._formValues?.psd?.regulatory_filing ?? ""}
                    render={({ field }) =>
                      <select className="theme-ip" {...field} data-cy="psd_regulatory_filing">
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>}
                  />
                ) : (
                  <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing}</p>
                )}
              </div>
            </label>
          </div>
        </div>
      ) : null }

      {!props.readable || props.attachments?.filter((attachment: any) => attachment.category === "PSD")?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          {!props.readable ? (
            <MultiFileUpload
              title="SOP File, Instrument Information"
              name="psdAttachments"
              dataCy="attachment_psdAttachments"
              attachments={methods.control._formValues.psd.attachments?.filter((attachment: any) => attachment.category === "PSD")}
              handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                let displayNames = props.material?.solid?.psd?.attachments ?? [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                  if (displayNames.includes(e?.target?.files?.[key].name)) {
                    return true;
                  }
                });
                if (isDuplicate) {
                  props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
                  setTimeout(() => props.dispatch(alertCloseAction()));
                  return;
                }
                methods.setValue("psd.attachments", [
                  ...(methods.getValues("psd.attachments") || []),
                  ...Object.keys(e?.target?.files ?? []).map(
                    (key: any) => {
                      const fileSize = e?.target?.files?.[key]?.size ?? 0;
                      return {
                        category: "PSD",
                        file: e?.target?.files?.[key],
                        error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                      };
                    }),
                ], { shouldDirty: true, shouldTouch: true });
              }}
              removeFile={(index: number) => {
                let categoryIndex = 0;
                methods.setValue("psd.attachments", (methods.getValues("psd.attachments") || []).filter((attachment: any, i: number) => {
                  if (attachment.category === "PSD") {
                    const ret = categoryIndex !== index;
                    categoryIndex += 1;
                    return ret;
                  }
                  return true;
                }), {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            />
          ) : (
            <MultiFileUpload
              title="SOP File, Instrument Information"
              name="psdFiles"
              readable={props.readable}
              attachments={props.attachments?.filter((attachment: any) => attachment.category === "PSD")}
              handleFileChange={props.handleFileChange}
              removeFile={props.removeFile}
              downloadFile={props.downloadFile}
            />
          )}
        </div>
      ) : null }

      {!props.readable || props.attachments?.filter((attachment: any) => attachment.category === "Histogram")?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          {
            !props.readable ?
              <MultiFileUpload
                title="Histogram"
                name="histogram"
                readable={props.readable}
                attachments={methods.control._formValues.psd.attachments?.filter((attachment: any) => attachment.category === "Histogram")}
                downloadFile={props.downloadFile}
                handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let displayNames = props.material?.solid?.psd?.attachments ?? [];
                  displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                  const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                      return true;
                    }
                  });
                  if (isDuplicate) {
                    props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
                    setTimeout(() => props.dispatch(alertCloseAction()));
                    return;
                  }
                  methods.setValue("psd.attachments", [
                    ...(methods.getValues("psd.attachments") || []),
                    ...Object.keys(e?.target?.files ?? []).map(
                      (key: any) => {
                        const fileSize = e?.target?.files?.[key]?.size ?? 0;
                        return {
                          category: "Histogram",
                          file: e?.target?.files?.[key],
                          error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                        };
                      }),
                  ], { shouldDirty: true, shouldTouch: true });
                }}
                removeFile={(index: number) => {
                  let categoryIndex = 0;
                  methods.setValue("psd.attachments", (methods.getValues("psd.attachments") || []).filter((attachment: any, i: number) => {
                    if (attachment.category === "Histogram") {
                      const ret = categoryIndex !== index;
                      categoryIndex += 1;
                      return ret;
                    }
                    return true;
                  }), {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }}
              />
              :
              <MultiFileUpload
                title="Histogram"
                name="histogram"
                readable={props.readable}
                attachments={props.attachments?.filter((attachment: any) => attachment.category === "Histogram")}
                handleFileChange={props.handleFileChange}
                removeFile={props.removeFile}
                downloadFile={props.downloadFile}
              />
          }
        </div>
      ) : null }
      </div>
    </>
  );
};

export default PSD;