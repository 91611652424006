import React, { useEffect, useState } from "react";
import Plot, { PlotParams } from "react-plotly.js";

const defaultPlot: PlotParams = {
  data: [
    {
      z: [
        [null, null],
        [null, null],
      ],
      type: "scatter",
      colorscale: "white",
    },
  ],
  layout: {
    title: "No Data Available",
    xaxis: { title: "IDs" },
    yaxis: { title: "Solubility(Output)" },
    height: 600,
    width: 900,
  },
};
export default function ContourGraph({ plot}: { plot: any }) {
  const [plotData, setPlotData] = useState<PlotParams>(defaultPlot);

  useEffect(() => {

    // If data is available, update the state to trigger re-render
    if (plot?.data && plot.data.length > 0) {
      setPlotData({
        data: [{
          x: plot.data.map((d: any,id:number) => id + 1),
          y: plot.data.map((d: any) => d.solubility),
          type: "scatter",
          mode: "markers",
          marker: {
            size: 10,
            color: "blue"
          },
          text: plot.data.map((d: any) => d.solubility),
          hoverinfo: "text",
          hoverlabel: {
            bgcolor: "#ffffff",
            font: { size: 14 },
            bordercolor: "#000000"
          },
        }],
        layout: {
          ...defaultPlot.layout,
          ...plot.layout,
          title: "Data Available",
          xaxis: {
            ...defaultPlot.layout.xaxis,
            title: "IDs"
          },
          yaxis: {
            ...defaultPlot.layout.yaxis,
            title: "Solubility (Output)"
          }
        },
      });
    } else {
      setPlotData(defaultPlot);
    }
  }, [plot]);


  return <Plot data={plotData.data} layout={plotData.layout} />;
}
