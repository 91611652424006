import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";

// helpers
import { solidAttachmentsTypes } from "../../../../../utils/materialHelper";
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits, setTitleNumberInput } from "../../../../../utils/common";
import { useEffect } from "react";

const General = (props: any) => {
    const { control } = useForm<any>({ defaultValues: props.initialData });
    const methods = useFormContext();
    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
        // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-lg-9">
                    {props.readable ? '' :
                        <>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <label className="ip-wrap" htmlFor="material_name">
                                            <span className="form-label">Material Name</span>
                                            <Controller
                                                name="material_name"
                                                control={props.control}
                                                render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <label className="ip-wrap" htmlFor="vendor">
                                            <span className="form-label">Vendor</span>
                                            <div className="input-wrap">
                                                <Controller
                                                    name="vendor_name"
                                                    control={props.control}
                                                    render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <label className="ip-wrap" htmlFor="lot_number" title={"Lot Number"}>
                                            <span className="form-label">Lot Number</span>
                                            <div className="input-wrap">
                                                <Controller
                                                    name="lot_number"
                                                    control={props.control}
                                                    render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="divider"></div>
                        </>
                    }
                    <div className="row">

                    {!props.readable || control?._formValues?.solid_form ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label
                                    className="ip-wrap"
                                    htmlFor="solidForm"
                                >
                                    <span className="form-label">
                                        Solid Form
                                    </span>
                                    <div className="input-wrap select-outer">
                                        {!props.readable ? (
                                            <Controller
                                                name="general.solid_form"
                                                control={methods.control}
                                                defaultValue={methods.control._formValues?.general?.solid_form??""}
                                                render={({ field }) =>
                                                    <select className="theme-ip"  {...field} data-cy="solid_from" >
                                                        <option value="">Select</option>
                                                        <option value="Co-Crystal">Co-Crystal</option>
                                                        <option value="Salt">Salt</option>
                                                        <option value="Parent">Parent</option>
                                                    </select>
                                                }
                                            />
                                        ) : (
                                            <p className="theme-ip pt-3 disabled">{control?._formValues?.solid_form}</p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.type ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label
                                    className="ip-wrap"
                                    htmlFor="type"
                                >
                                    <span className="form-label">
                                        Type
                                    </span>
                                    <div className="input-wrap select-outer">
                                        {!props.readable ? (
                                            <Controller
                                                name="general.type"
                                                control={methods.control}
                                                defaultValue={methods.control._formValues?.general?.type??""}
                                                render={({ field }) =>
                                                    <select className="theme-ip" {...field} data-cy="solid_type">
                                                        <option value="">Select</option>
                                                        <option value="API">API</option>
                                                        <option value="Impurity">Impurity</option>
                                                        <option value="Intermediate">Intermediate</option>
                                                        <option value="Starting Material">Starting Material</option>
                                                        <option value="Reagents">Reagents</option>
                                                        <option value="Excipients">Excipients</option>
                                                        <option value="Catalyst">Catalyst</option>
                                                    </select>}
                                            />
                                        ) : (
                                            <p className="theme-ip pt-3 disabled">{control?._formValues?.type}</p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.application ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="application">
                                    <span className="form-label">Application</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.application"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.application??""}
                                            render={({ field }) => <input data-cy="application" className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.application}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.counterion ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="counterion" title="The ion that accompanies an ionic species
                                 in order to maintain electric neutrality">
                                    <span className="form-label">Counterion</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.counterion"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.counterion??""}
                                            render={({ field }) => <input
                                              data-cy="counteration"
                                              className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.counterion}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.color_l ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="color_l">
                                    <span className="form-label">Color-L</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.color_l"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.color_l??""}
                                            render={({ field }) => <input
                                              data-cy="color_l"
                                              className="theme-ip" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}  {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.color_l}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.color_a ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="color_a">
                                    <span className="form-label">Color-A</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.color_a"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.color_a??""}
                                            render={({ field }) => <input
                                              data-cy="color_a"
                                              className="theme-ip" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}  {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.color_a}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.color_b ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="color_b">
                                    <span className="form-label">Color-B</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.color_b"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.color_b??""}
                                            render={({ field }) => <input
                                              data-cy="color_b"
                                              className="theme-ip" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}  {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.color_b}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.bulk_modulus ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="bulk_modulus">
                                    <span className="form-label">Bulk Modulus (Pa)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.bulk_modulus"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.bulk_modulus??""}
                                            render={({ field }) => <input
                                              data-cy="bulk_modulas_pa"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.bulk_modulus}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.heat_of_fusion ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="heat_of_fusion">
                                    <span className="form-label">Heat of Fusion (kJ/mol)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.heat_of_fusion"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.heat_of_fusion??""}
                                            render={({ field }) => <input
                                              data-cy="heat_fusion"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.heat_of_fusion}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.main_impurity_1 ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="main_impurity_1">
                                    <span className="form-label">Main Impurity 1</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.main_impurity_1"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.main_impurity_1??""}
                                            render={({ field }) => <input
                                              data-cy="main_impurity_1"
                                              className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled"
                                           style={{
                                               whiteSpace: "nowrap",
                                               overflowX: "auto",
                                               overflowY: "hidden",
                                               textOverflow: "unset"
                                           }}
                                        >{control?._formValues?.main_impurity_1}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.main_impurity_2 ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="main_impurity_2">
                                    <span className="form-label">Main Impurity 2</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.main_impurity_2"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.main_impurity_2??""}
                                            render={({ field }) => <input
                                              data-cy="main_impurity_2"
                                              className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled" style={{
                                            whiteSpace: "nowrap",
                                            overflowX: "auto",
                                            overflowY: "hidden",
                                            textOverflow: "unset"
                                        }}>{control?._formValues?.main_impurity_2}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.main_impurity_3 ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="main_impurity_3">
                                    <span className="form-label">Main Impurity 3</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.main_impurity_3"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.main_impurity_3??""}
                                            render={({ field }) => <input
                                              data-cy="main_impurity_3"
                                              className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled" style={{
                                            whiteSpace: "nowrap",
                                            overflowX: "auto",
                                            overflowY: "hidden",
                                            textOverflow: "unset"
                                        }}>{control?._formValues?.main_impurity_3}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.log_s || methods.control._formValues?.log_S ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="log_s">
                                    <span className="form-label">Log S</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.log_s"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.log_S ?? methods.control._formValues?.general?.log_s ?? ""}
                                            // defaultValue={methods.control._formValues?.general?.log_s??""}
                                            render={({ field }) => <input
                                              data-cy="logS"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.log_s ?? methods.control._formValues?.log_S}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.pKa_1 ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="pKa_1">
                                    <span className="form-label">pKa 1</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.pKa_1"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.pKa_1??""}
                                            render={({ field }) => <input
                                              data-cy="pka1"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.pKa_1}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.pKa_2 ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="pKa_2">
                                    <span className="form-label">pKa 2</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.pKa_2"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.pKa_2??""}
                                            render={({ field }) => <input
                                              data-cy="pka2"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.pKa_2}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.pKa_3 ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="pKa_3">
                                    <span className="form-label">pKa 3</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.pKa_3"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.pKa_3??""}
                                            render={({ field }) => <input
                                              data-cy="pka3"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.pKa_3}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.specific_heat_capacity ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="specific_heat_capacity">
                                    <span className="form-label">Specific Heat Capacity (Cp) (kJ/K)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.specific_heat_capacity"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.specific_heat_capacity??""}
                                            render={({ field }) => <input
                                              data-cy="specific_heat_capacity"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.specific_heat_capacity}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.surface_area ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="surface_area">
                                    <span className="form-label">Surface Area (m2)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.surface_area"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.surface_area??""}
                                            render={({ field }) => <input
                                              data-cy="surface_area"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.surface_area}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.surface_energy ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="surface_energy">
                                    <span className="form-label">Surface Energy (J/m2)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.surface_energy"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.surface_energy??""}
                                            render={({ field }) => <input
                                              data-cy="surface_energy"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.surface_energy}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.thermal_conductivity ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="thermal_conductivity">
                                    <span className="form-label">Thermal Conductivity (W/mK)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.thermal_conductivity"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.thermal_conductivity??""}
                                            render={({ field }) => <input
                                              data-cy="thermal_conductivity"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.thermal_conductivity}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.yield_stress ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="yield_stress">
                                    <span className="form-label">Yield Stress (N/m2)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.yield_stress"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.yield_stress??""}
                                            render={({ field }) => <input
                                              data-cy="yield_stress"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.yield_stress}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.young_modulus ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="youngs_modulus">
                                    <span className="form-label">Young's Modulus (Pa)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.young_modulus"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.young_modulus??""}
                                            render={({ field }) => <input
                                              data-cy="young_modulus"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.young_modulus}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.form ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label
                                    className="ip-wrap"
                                    htmlFor="form"
                                >
                                    <span className="form-label">
                                        Form
                                    </span>
                                    <div className="input-wrap select-outer">
                                        {!props.readable ? (
                                            <Controller
                                                name="general.form"
                                                control={methods.control}
                                                defaultValue={methods.control._formValues?.general?.form??""}
                                                render={({ field }) =>
                                                    <select className="theme-ip"
                                                            data-cy="form"
                                                            {...field}>
                                                        <option value="">Select</option>
                                                        <option value="Crystal">Crystal</option>
                                                        <option value="Amorphous">Amorphous</option>
                                                    </select>}
                                            />
                                        ) : (
                                            <p className="theme-ip pt-3 disabled">{control?._formValues?.form}</p>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.polymorph ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="polymorph">
                                    <span className="form-label">Polymorph</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.polymorph"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.polymorph??""}
                                            render={({ field }) => <input
                                              data-cy="polymorph"
                                              className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.polymorph}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.crystal_shape ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="crystal_shape">
                                    <span className="form-label">Crystal Shape</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.crystal_shape"
                                            control={methods.control}
                                            defaultValue={methods.control._formValues?.general?.crystal_shape??""}
                                            render={({ field }) => <input
                                              data-cy="crystal_shape"
                                              className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.crystal_shape}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    {!props.readable || control?._formValues?.glass_transition_temperature ? (
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <label className="ip-wrap" htmlFor="glass_transition_temperature">
                                    <span className="form-label">Glass Transition Temperature (C)</span>
                                    {!props.readable ? (
                                        <Controller
                                            name="general.glass_transition_temperature"
                                            control={props.control}
                                            defaultValue={methods.control._formValues?.general?.glass_transition_temperature??""}
                                            render={({ field }) => <input
                                              data-cy="glass_transition_temp"
                                              type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                        />
                                    ) : (
                                        <p className="theme-ip pt-3 disabled">{control?._formValues?.glass_transition_temperature}</p>
                                    )}
                                </label>
                            </div>
                        </div>
                    ) : null }

                    </div>
                    {/* generic form */}
                </div>

                {!props.readable || props?.attachments?.length > 0 ? (
                <div className="col-lg-3 col-md-6">
                    {!props.readable ? (
                        <MultiFileUpload
                            dataCy="attachment_Files"
                            title="Files"
                            name="generalAttachments"
                            hasAttachmentTypes={true}
                            attachmentsTypes={solidAttachmentsTypes}
                            setSelectedAttachmentType={(value: any) => {
                                methods.setValue('general.attachment_type', value);
                            }}
                            attachments={methods.getValues('general.attachments')??[]}
                            handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (!methods.control._formValues.general?.attachment_type?.length) {
                                    props.dispatch(alertOpenAction('Please select file category.', 'error'));
                                    setTimeout(() => props.dispatch(alertCloseAction()));
                                    return;
                                }
                                methods.setValue('general.attachments', [
                                    ...(methods.getValues('general.attachments') || []),
                                    ...Object.keys(e?.target?.files ?? []).map(
                                        (key: any) => {
                                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                                            return {
                                                category: methods.control._formValues.general.attachment_type,
                                                file: e?.target?.files?.[key],
                                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                                            }
                                        })
                                ], { shouldDirty: true, shouldTouch: true });
                            }}
                            removeFile={(index: number, name: string) => {
                                methods.setValue('general.attachments', (methods.getValues('general.attachments')||[]).filter((attachment: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true })
                            }}
                        />
                    ) : (
                        <MultiFileUpload
                            title="Upload File"
                            name="uploadFiles"
                            readable={props.readable}
                            handleFileChange={() => console.log()}
                            removeFile={() => console.log()}
                            attachments={props.attachments}
                            downloadFile={props.downloadFile}
                        />
                    )}
                </div>
                ) : null }
            </div>
        </>
    );
};

export default General;