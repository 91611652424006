import { useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import { useEffect } from "react";
import { setTitleNumberInput } from "../../../../../utils/common";

const SEM = (props: any) => {
  const methods = useFormContext();
  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
  }, []);

  return (
    <>
      <div className="row">

      {!props.readable || props.attachments?.filter((attachment: any) => attachment.category === "SEM")?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          {!props.readable ? (
            <MultiFileUpload
              title="SEM Images"
              name="semAttachments"
              dataCy="attachment_semAttachments"
              attachments={methods.control._formValues.sem.attachments?.filter((attachment: any) => attachment.category === "SEM")}
              handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                let displayNames = props.material?.solid?.sem?.attachments ?? [];
                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                  if (displayNames.includes(e?.target?.files?.[key].name)) {
                    return true;
                  }
                });
                if (isDuplicate) {
                  props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
                  setTimeout(() => props.dispatch(alertCloseAction()));
                  return;
                }
                methods.setValue("sem.attachments", [
                  ...(methods.getValues("sem.attachments") || []),
                  ...Object.keys(e?.target?.files ?? []).map(
                    (key: any) => {
                      const fileSize = e?.target?.files?.[key]?.size ?? 0;
                      return {
                        category: "SEM",
                        file: e?.target?.files?.[key],
                        error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                      };
                    }),
                ], { shouldDirty: true, shouldTouch: true });
              }}
              removeFile={(index: number) => {
                let categoryIndex = 0;
                methods.setValue("sem.attachments", (methods.getValues("sem.attachments") || []).filter((attachment: any, i: number) => {
                  if (attachment.category === "SEM") {
                    const ret = categoryIndex !== index;
                    categoryIndex += 1;
                    return ret;
                  }
                  return true;
                }), {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            />
          ) : (
            <MultiFileUpload
              title="SEM Images"
              name="semFiles"
              readable={props.readable}
              attachments={props.attachments?.filter((attachment: any) => attachment.category === "SEM")}
              handleFileChange={props.handleFileChange}
              removeFile={props.removeFile}
              downloadFile={props.downloadFile}
            />
          )}
        </div>
      ) : null }

      {!props.readable || props.attachments?.filter((attachment: any) => attachment.category === "Microscopy")?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          {!props.readable ?
            <MultiFileUpload
            title="Microscopy"
            name="microscopy"
            dataCy="attachment_microscopy"
            attachments={methods.control._formValues.sem.attachments?.filter((attachment: any) => attachment.category === "Microscopy")}
            handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let displayNames = props.material?.solid?.sem?.attachments ?? [];
              displayNames = displayNames?.map((attachment: any) => attachment.display_name);
              const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                if (displayNames.includes(e?.target?.files?.[key].name)) {
                  return true;
                }
              });
              if (isDuplicate) {
                props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
                setTimeout(() => props.dispatch(alertCloseAction()));
                return;
              }
              methods.setValue("sem.attachments", [
                ...(methods.getValues("sem.attachments") || []),
                ...Object.keys(e?.target?.files ?? []).map(
                  (key: any) => {
                    const fileSize = e?.target?.files?.[key]?.size ?? 0;
                    return {
                      category: "Microscopy",
                      file: e?.target?.files?.[key],
                      error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                    };
                  }),
              ], { shouldDirty: true, shouldTouch: true });
            }}
            removeFile={(index: number) => {
              let categoryIndex = 0;
              methods.setValue("sem.attachments", (methods.getValues("sem.attachments") || []).filter((attachment: any, i: number) => {
                if (attachment.category === "Microscopy") {
                  const ret = categoryIndex !== index;
                  categoryIndex += 1;
                  return ret;
                }
                return true;
              }), {
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
          />
            :
            <MultiFileUpload
              title="Microscopy"
              name="microscopy"
              dataCy="attachment_microscopy"
              readable={props.readable}
              attachments={props.attachments?.filter((attachment: any) => attachment.category === "Microscopy")}
              handleFileChange={props.handleFileChange}
              removeFile={props.removeFile}
              downloadFile={props.downloadFile}
            />
          }
        </div>
      ) : null }

      </div>
    </>
  );
};

export default SEM;