import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { alertOpenAction } from "../../../../redux/actions";
import MaterialService from "../../../../services/materialService";
import { apiRoutes } from "../../../../utils/apiRoutes";
import CustomLoader from '../../../../components/common/CustomLoader';
import Button, {ButtonIcons,ButtonTypes} from '../../../../components/common/Button';

interface LogEntry {
  task: string;
  stdout: string;
  stderr: string;
  timestamp?: string;
  status?: string;
}

interface PreviousLogsProps {
  auth: any;
  vendorName: string;
  material: string;
  lotNumber: string;
  mappedName: string;
}

const parseLogEntry = (log: LogEntry) => {
 const timestampMatch = log.stdout.match(/\[(\d{2})\.(\d{2})\|(\d{2}:\d{2}:\d{2})\]/);

  if (!timestampMatch) return { ...log, timestamp: '', status: 'Unknown' };

  const day = timestampMatch[1];
  const month = timestampMatch[2];
  const time = timestampMatch[3];

  const formattedTimestamp = `[${month}.${day} | ${time} UTC]`;
  const status = !log.stderr ? 'Success' : 'Error';
  return { ...log, timestamp: formattedTimestamp, status };
};

const LogEntryComponent = ({ log, isSelected, onClick,mappedName }: { 
  log: LogEntry; 
  isSelected: boolean; 
  mappedName: string; 
  onClick: () => void; 
}) => {
  const getStatusBadge = () => {
    switch (log.status?.toLowerCase()) {
      case 'success':
        return <span className="text-sm text-green-600">Success</span>;
      case 'error':
        return <span className="text-sm text-red-600">Error</span>;
      default:
        return <span className="text-sm text-yellow-600">Warning</span>;
    }
  };
  const handleDownloadLog = () => {
    const logContent = `
      Task: ${log.task}
      Timestamp: ${log.timestamp}
      Status: ${log.status}

      Standard Output:
      ${log.stdout}

      Standard Error:
      ${log.stderr}
    `;

    const blob = new Blob([logContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${log.task}_${mappedName}_${log.timestamp}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
  };

  return (
    <div 
      onClick={onClick}
      className="border-b border-gray-200 py-3 cursor-pointer hover:bg-gray-50"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <span className="text-gray-700">{log.timestamp}</span>
          {getStatusBadge()}
        </div>
        <div className="flex justify-items-end gap-4">
        <Button isIconButton onClick={handleDownloadLog} icon={ButtonIcons.DOWNLOAD} type={ButtonTypes.PRIMARY} />
        <svg
          className={`w-5 h-5 transition-transform duration-200 text-gray-400
            ${isSelected ? 'rotate-180' : ''}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
        </div>
      </div>
      
      <div className="text-sm text-gray-800 mt-1">
        {log.task}
      </div>

      {isSelected && (
        <div className="mt-3 bg-gray-50 p-3 rounded-md">
          <pre className="text-xs text-gray-600 whitespace-pre-wrap font-mono">
            {log.stdout}
            {log.stderr && (
              <>
                {"\n"}
                <span className="text-red-600">{log.stderr}</span>
              </>
            )}
          </pre>
          <div className='flex justify-end mt-2'>
        </div>
        </div>
      )}
    </div>
  );
};

const PreviousLogs: React.FC<PreviousLogsProps> = ({ 
  auth, 
  vendorName, 
  material, 
  lotNumber,
  mappedName
}) => {
  const dispatch = useDispatch();
  const [previousLogs, setPreviousLogs] = useState<LogEntry[]>([]);
  const [selectedLog, setSelectedLog] = useState<LogEntry | null>(null);
  const [fetchingLogs, setFetchingLogs] = useState(false);

  useEffect(() => {
    setPreviousLogs([]);
  }, [vendorName, material, lotNumber]);

  const fetchLogs = async () => {
    // if (!mappedName) return;
    setFetchingLogs(true);
    try {
      const response = await fetch(`${apiRoutes.COSMO_API_URL}/solubility_logs_by_material?name=${mappedName}`, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      // Parse the response as JSON
      const data = await response.json();

      if (data?.logs) {
        const parsedLogs = data.logs
          .filter((log: LogEntry) => 
            log.task === "SolubilityScreen" && 
            log.stdout.includes(mappedName)
          )
          .map(parseLogEntry)
          .sort((a: LogEntry, b: LogEntry) => {
            return (b.timestamp || '').localeCompare(a.timestamp || '');
          });

        setPreviousLogs(parsedLogs);
      } else {
        setPreviousLogs([]);
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      dispatch(alertOpenAction("Error fetching logs", "error"));
      setPreviousLogs([]);
    } finally {
      setFetchingLogs(false);
    }
  };

  return (
    <div className="w-1/2 ml-4 border rounded-lg p-4 bg-white">
      <div className="flex justify-between items-center mb-2">
        <h4 className="text-lg font-semibold">Previous Runs</h4>
        <span className="text-sm text-gray-400">
          Click on a log to view details
        </span>
      </div>
      
      <button 
        onClick={fetchLogs}
        className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
        disabled={fetchingLogs}
      >
        Fetch Logs
      </button>
      {fetchingLogs && <CustomLoader />}
      <div className="divide-y divide-gray-200">
        {previousLogs.map((log, index) => (
          <LogEntryComponent
            key={index}
            log={log}
            isSelected={selectedLog?.timestamp === log.timestamp}
            onClick={() => setSelectedLog(
              selectedLog?.timestamp === log.timestamp ? null : log
            )}
            mappedName={mappedName}
          />
        ))}
      </div>
    </div>
  );
};

export default PreviousLogs;
