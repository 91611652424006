import { useEffect, useState, useRef, useCallback } from "react";
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";
import AsyncSelect from 'react-select/async';


// components
import UnchainedLabs from "./UnchainedLabs";
import SelectMaterialHeader from "./SelectMaterialHeader";
import Layout from "../../../components/layout";
import TakedaModels from "../../../components/solubility/TakedaModels";
import CustomLoader from "../../../components/common/CustomLoader";
import RenderIf from "../../../components/common/RenderIf";
import BodyHeader from "../../../components/layout/BodyHeader";
import { ButtonElementType, ButtonIcons, ButtonProps, ButtonTypes } from "../../../components/common/Button";

// modals
import HelpModal from "../../../components/modals/HelpModal";
import CancelConfirmModal from "../../../components/modals/CancelFormModal";

// context
import { useUser } from "../../../components/context/UserContext";

// services
import FileService from "../../../services/fileService";
import MaterialService from "../../../services/materialService";

// api routes
import { apiRoutes } from "../../../utils/apiRoutes";

// redux action
import { materialAction } from "../../../redux/actions/materialActions";
import { alertOpenAction, alertCloseAction } from "../../../redux/actions";

// props
import { RootState } from "../../../redux/store";

// helpers
import { findFile, getUpdatedFiles } from "../../../utils/common";
import { MaterialSolubilityHelp } from "../../../utils/helpContent";
import { checkDuplicateFiles } from "../../../utils/materialHelper";
import { layoutTitles, MATERIAL_SOLUBILITY_PAGE_TITLE } from "../../../utils/constant";

const initialData: any = {
    vendor_name: "",
    material_name: "",
    lot_number: "",
    model: "",
    solvent: "",
    solvent1: "",
    solvent2: "",
    temperature: 5,
    solubility_value: "",
    file_path: "",
    turbidity_measured: "",
    visual: false,
    plot: false,
    attachments: {
        model3: [],
        solubility: {
            unchainedLab: [],
            hplc: [],
            crystal16: [],
            crystalline: [],
            gravimetryMethod: [],
            solubilityCurve1: [],
            solubilityCurve2: [],
            solubilityCurve3: [],
            solubilityCurve4: [],
            solubilityCurve5: [],
        },
    },
    graph: {
        labels: [],
        ethanol: [],
        methanol: [],
    }
}

const MaterialSolubility = () => {
    // init
    const { user } = useUser();
    const history = useHistory();
    const dispatch = useDispatch();
    const { authState } = useOktaAuth();
    const methods = useForm({ defaultValues: initialData });
    const material = useSelector((state: RootState) => state.material);

    const auth: any = authState ? authState?.accessToken : '';
    const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

    // state
    const [isDirect, setIsDirect] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [model3Saved, setModel3Saved] = useState<number>(0);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
    const [simulation, setSimulation] = useState<any>(null);
    const [headerFilters, setheaderFilters] = useState<any>(null);
    const [submitFn, setSubmitFn] = useState<any>(null);
    const [closeFn, setCloseFn] = useState<any>(null);
    const [credits, setCredits] = useState<string>('');

    const { vendor_name, material_name, lot_number } = methods.control._formValues;
    const isTabDisabled = !vendor_name || !material_name || !lot_number;

    // method to change files format.
    const parseFile = (items: any[]) => {
        return items.map((item: any) => ({
            category: item.category,
            file: {
                name: item.display_name,
                type: item.mime_type,
            },
            error: false,
            isUpload: true,
        }));
    }

    // method to reset updated files.
    const resetUpload = (simulation: any, override = {}) => {
        let data: any = { ...initialData, ...methods.control._formValues };
        data.vendor_name = simulation?.vendor_name ?? "";
        data.material_name = simulation?.material_name ?? "";
        data.lot_number = simulation?.lot_number ?? "";
        data.model = methods.control._formValues?.model ? methods.control._formValues?.model : simulation?.model ?? "";
        data.solvent = methods.control._formValues?.solvent ? methods.control._formValues?.solvent : simulation?.solvent ?? "";
        data.temperature = methods.control._formValues?.temperature ? methods.control._formValues?.temperature : simulation?.temperature ?? 5;
        data.turbidity_measured = methods.control._formValues?.turbidity_measured ? methods.control._formValues?.turbidity_measured : simulation?.turbidity_measured ?? 1017.16;
        data.solubility_value = methods.control._formValues?.solubility_value ? methods.control._formValues?.solubility_value : simulation?.solubility_value ?? "";
        data.visual = methods.control._formValues?.visual ?? false;
        data.plot = methods.control._formValues?.plot ?? false;
        data.attachments = { ...initialData.attachments };

        let allSolubilityAttachmentNames: Array<string> = [];

        for (const attachment in initialData.attachments.solubility) {
            if (Object.prototype.hasOwnProperty.call(initialData.attachments.solubility, attachment)) {
                allSolubilityAttachmentNames.push(attachment);
            }
        }

        for (const attachment of allSolubilityAttachmentNames) {
            data.attachments.solubility[attachment] = simulation?.[attachment] ? parseFile(simulation[attachment]) : [];
        }

        if (data.model == "Model 3") {
            data.solubility_value = "";
            data.solvent = "";
            data.solvent1 = "";
            data.temperature = 5;
            data.turbidity_measured = "";
        }

        methods.reset({ ...data, ...override });
    }

    // method trigger on when user fill all required fields.
    const handleSelectedFields = (data: any) => {
        setLoading(data.loading);
        setheaderFilters(data);
        getMaterial(data.vendor_name, data.material_name, data.lot_number);
        setTabIndex(2);
        setIsDirect(false);
    }

    // method to return material
    const getMaterial = async (vendor_name: string, material_name: string, lot_number: string) => {
        const payload = {
            uid: `${auth?.claims?.uid}`,
            vendor_name,
            material_name,
            lot_number,
        };
        setLoading(true);
        const res = await MaterialService.create(apiRoutes.FIND_MATERIAL, payload);

        setLoading(false);
        if (res?.data?.code == 200) {
            dispatch(materialAction(res?.data?.body));
            if (res?.data?.body?.id) getSaveSimulations(res?.data?.body?.id);
        } else {
            dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return null;
        }
    }

    // method to get saved material
    const getSaveSimulations = async (id: string) => {
        setLoading(true); // enable loading
        const res = await MaterialService.create("/simulations/get-simulations-files-data", {
            id,
            uid: auth?.claims?.uid,
            simulation_type: "material"
        });

        setLoading(false); // disable loading
        if (res?.data?.code == 200) {
            const result = res?.data;
            if (result.body && Object.keys(result.body).length) {
                setSimulation(result.body);
            }
        } else {
            dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return false;
        };
    }

    // method to return graph data
    const handleGraph = async () => {
        const { vendor_name, material_name, lot_number } = methods.control._formValues;
        const payload = {
            uid: auth?.claims?.uid,
            vendor_name,
            material_name,
            lot_number,
        };
        setLoading(true); // enable loading
        const res = await MaterialService.create("/simulations/get-graph-data", payload);
        setLoading(false); // disable loading
        if (res?.data?.code === 200) {
            const { ethanol, methanol } = res?.data?.body;
            if (ethanol?.length || methanol?.length) {
                // methods.control._formValues.plot
                const data = {
                    ...methods.control._formValues,
                    ...{ graph: getData(ethanol, methanol) }
                };
                methods.reset(data);
            }
        } else {
            dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    // method trigger to format graph data.
    const getData = (ethanol: any[], methanol: any[]) => {
        const result: any = {
            labels: [],
            ethanol: [],
            methanol: [],
        }
        const xAxis = Array.from(new Set([
            ...ethanol.map((item: any) => item.x),
            ...methanol.map((item: any) => item.x),
        ])).sort((a: number, b: number) => a > b ? 1 : -1);

        const graphData = xAxis.reduce((e: any, t: any) => {
            const ethanolY = ethanol?.find((item: any) => item.x === t);
            const methanolY = methanol?.find((item: any) => item.x === t);
            e[t] = {
                ethanol: ethanolY ? Math.round(ethanolY.y) : 0,
                methanol: methanolY ? Math.round(methanolY.y) : 0,
            };
            return e;
        }, []);
        result.labels = xAxis;
        result.ethanol = Object.keys(graphData).map((key: any) => graphData[key]['ethanol']);
        result.methanol = Object.keys(graphData).map((key: any) => graphData[key]['methanol']);
        return result;
    }

    const uploadFile = async (file: any, attachments: any[]) => {
        let newAttachment = await findFile(attachments, file);
        if (newAttachment) {
            const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
            const res = await fetch(
                file.signedUrl,
                {
                    method: 'PUT',
                    body: blob,
                }
            );
            return res.ok ? true : false;
        }
        return false;
    }

    const uploadFileAPI = async (attachments: any[]) => {
        let result = {
            code: 200,
            status: "success",
            message: "",
            data: {},
        }
        // file upload API
        const filesResponse = await FileService.create("/files/upload", {
            id: material?.item?.id ?? "",
            file_for: 'Material',
            files: attachments.map(
                (attachment) => ({
                    category: attachment?.category,
                    key: attachment.file?.name,
                    mimeType: attachment?.file?.type
                })),
        });
        if (filesResponse?.status === 200) {
            const filesResult = filesResponse?.data;
            return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file, attachments)))
                .then(async (res) => {
                    // update material object
                    filesResult.body.files.map((file: any) => {
                        delete file.signedUrl;
                        delete file.location;
                        return file;
                    });

                    const r = filesResult?.body?.files?.reduce((e: any, t: any) => {
                        switch (t.category) {
                            case 'Unchained Lab Data':
                                e.unchainedLab = [...e?.unchainedLab, t];
                                break;
                            case 'HPLC Data':
                                e.hplc = [...e?.hplc, t];
                                break;
                            case 'Crystal 16 Data':
                                e.crystal16 = [...e?.crystal16, t];
                                break;
                            case 'Crystalline Data':
                                e.crystalline = [...e?.crystalline, t];
                                break;
                            case 'Gravimetry Method':
                                e.gravimetryMethod = [...e?.gravimetryMethod, t];
                                break;
                            case 'Solubility Curve 1':
                                e.solubilityCurve1 = [...e?.solubilityCurve1, t];
                                break;
                            case 'Solubility Curve 2':
                                e.solubilityCurve2 = [...e?.solubilityCurve2, t];
                                break;
                            case 'Solubility Curve 3':
                                e.solubilityCurve3 = [...e?.solubilityCurve3, t];
                                break;
                            case 'Solubility Curve 4':
                                e.solubilityCurve4 = [...e?.solubilityCurve4, t];
                                break;
                            case 'Solubility Curve 5':
                                e.solubilityCurve5 = [...e?.solubilityCurve5, t];
                                break;
                        }
                        return e;
                    }, {
                        unchainedLab: [],
                        hplc: [],
                        crystal16: [],
                        crystalline: [],
                        gravimetryMethod: [],
                        solubilityCurve1: [],
                        solubilityCurve2: [],
                        solubilityCurve3: [],
                        solubilityCurve4: [],
                        solubilityCurve5: [],
                    });

                    const payload = {
                        material_id: material?.item?.id ?? "",
                        material_name: material?.item?.material_name ?? "",
                        vendor_name: material?.item?.vendor_name ?? "",
                        lot_number: material?.item?.lot_number ?? "",
                        uid: material?.item?.uid ?? "",
                        simulation_type: "material",
                        unchainedLab: await getUpdatedFiles([...simulation?.unchainedLab ?? [], ...r.unchainedLab], methods?.control?._formValues?.attachments?.solubility.unchainedLab ?? [], !0),
                        hplc: await getUpdatedFiles([...simulation?.hplc ?? [], ...r.hplc], methods?.control?._formValues?.attachments?.solubility.hplc ?? [], !0),
                        crystal16: await getUpdatedFiles([...simulation?.crystal16 ?? [], ...r.crystal16], methods?.control?._formValues?.attachments?.solubility.crystal16 ?? [], !0),
                        crystalline: await getUpdatedFiles([...simulation?.crystalline ?? [], ...r.crystalline], methods?.control?._formValues?.attachments?.solubility.crystalline ?? [], !0),
                        gravimetryMethod: await getUpdatedFiles([...simulation?.gravimetryMethod ?? [], ...r.gravimetryMethod], methods?.control?._formValues?.attachments?.solubility.gravimetryMethod ?? [], !0),
                        solubilityCurve1: await getUpdatedFiles([...simulation?.solubilityCurve1 ?? [], ...r.solubilityCurve1], methods?.control?._formValues?.attachments?.solubility.solubilityCurve1 ?? [], !0),
                        solubilityCurve2: await getUpdatedFiles([...simulation?.solubilityCurve2 ?? [], ...r.solubilityCurve2], methods?.control?._formValues?.attachments?.solubility.solubilityCurve2 ?? [], !0),
                        solubilityCurve3: await getUpdatedFiles([...simulation?.solubilityCurve3 ?? [], ...r.solubilityCurve3], methods?.control?._formValues?.attachments?.solubility.solubilityCurve3 ?? [], !0),
                        solubilityCurve4: await getUpdatedFiles([...simulation?.solubilityCurve4 ?? [], ...r.solubilityCurve4], methods?.control?._formValues?.attachments?.solubility.solubilityCurve4 ?? [], !0),
                        solubilityCurve5: await getUpdatedFiles([...simulation?.solubilityCurve5 ?? [], ...r.solubilityCurve5], methods?.control?._formValues?.attachments?.solubility.solubilityCurve5 ?? [], !0),
                    }
                    const materialResponse = await MaterialService.create("/simulations/save-simulations-files-data", payload);
                    if (materialResponse?.data?.code == 200) {
                        const materialResult = materialResponse?.data;
                        // setSimulation(materialResult.body);
                        resetUpload(materialResult.body);
                        result.data = materialResult.body;
                        result.status = "success";
                        result.message = "Data saved.";
                        return result;
                    }
                    result.code = 400;
                    result.status = "error";
                    result.message = "Oops! something went wrong.";
                    return result;
                })
                .catch(err => {
                    result.code = 400;
                    result.status = "error";
                    result.message = "Oops! something went wrong.";
                    return result;
                });
        }
        result.code = 400;
        result.status = "error";
        result.message = "Oops! something went wrong.";
        return result;
    }

    // method trigger on calculate solubility.
    const handleSolubility = async () => {
        let {
            vendor_name,
            material_name,
            lot_number,
            temperature,
            turbidity_measured,
            model,
            solvent,
            attachments,
        } = methods.control._formValues;
        solvent = solvent.value;
        if (!vendor_name?.trim()?.length || !material_name?.trim()?.length || !lot_number?.trim()?.length) {
            dispatch(alertOpenAction('Please select material first.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return;
        }

        if (!model?.length) {
            dispatch(alertOpenAction('Please select model.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return;
        }

        if (model === 'Model 3') {
            const model3Files = attachments?.model3 ?? [];
            const turbudity_measured = turbidity_measured;
            if (!model3Files?.length) {
                dispatch(alertOpenAction('Please select Model3 PNG file.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }

            if (!turbidity_measured) {
                dispatch(alertOpenAction('Please enter turbidity value.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }

            if (!solvent) {
                dispatch(alertOpenAction('Please select a solvent', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }

            setLoading(true); // enable loading

            const result = await uploadModel3Api(model3Files, turbudity_measured);


            if (result) {
                methods.setValue('solubility_value', result?.solubility ?? '');
                methods.setValue('file_path', result?.file_path ?? '');
                methods.setValue('turbidity_measured', result?.turbidity_measured ?? '');
            } else {
                setLoading(false);
                dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
            }
            return;
        }
        if (!solvent?.length) {
            dispatch(alertOpenAction('Please select solvent.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return;
        }

        const payload = {
            x: temperature,
            model,
            solvent,
        }

        setLoading(true); // enable loading
        const res = await MaterialService.create("/simulations/model1-2", payload);
        setLoading(false); // disable loading
        if (res?.data?.code === 200) {
            methods.setValue('solubility_value', res?.data?.body?.Y ?? '');
        } else {
            dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    const saveUploadSolubility = async () => {
        let result: any = {
            code: 200,
            status: "success",
            message: "",
            data: {},
        }

        let attachments: any = [];
        if (methods.control._formValues.attachments.solubility) {
            attachments = Object.keys(methods.control._formValues.attachments.solubility).reduce((e: any, t: any) => {
                let e2: any = methods.control._formValues.attachments.solubility[t];
                if (e2 !== null && e2 !== undefined) {
                    e = [...e, ...methods.control._formValues.attachments.solubility[t]];
                }
                return e;
            }, []);
        }

        if (attachments.length) {
            // check if files are duplicate in same category.
            const isDuplicate = await (await checkDuplicateFiles(attachments))?.map((item: any) => item.isDuplicate).includes(true);
            if (isDuplicate) {
                result.code = 400;
                result.status = "error";
                result.message = "Please remove duplicate files.",
                    result.data = {};
                return result;
            }
            // updated files list
            const newAttachments = attachments?.filter((file: any) => !file.isUpload);
            if (newAttachments.length)
                return { ...result, ...await uploadFileAPI(newAttachments) };
        }

        const payload = {
            material_id: material?.item?.id ?? "",
            material_name: material?.item?.material_name ?? "",
            vendor_name: material?.item?.vendor_name ?? "",
            lot_number: material?.item?.lot_number ?? "",
            uid: material?.item?.uid ?? "",
            simulation_type: "material",
            unchainedLab: await getUpdatedFiles(simulation?.unchainedLab ?? [], methods?.control?._formValues?.attachments?.solubility?.unchainedLab ?? [], !1),
            hplc: await getUpdatedFiles(simulation?.hplc ?? [], methods?.control?._formValues?.attachments?.solubility?.hplc ?? [], !1),
            crystal16: await getUpdatedFiles(simulation?.crystal16 ?? [], methods?.control?._formValues?.attachments?.solubility?.crystal16 ?? [], !1),
            crystalline: await getUpdatedFiles(simulation?.crystalline ?? [], methods?.control?._formValues?.attachments?.solubility?.crystalline ?? [], !1),
            gravimetryMethod: await getUpdatedFiles(simulation?.gravimetryMethod ?? [], methods?.control?._formValues?.attachments?.solubility?.gravimetryMethod ?? [], !1),
            solubilityCurve1: await getUpdatedFiles(simulation?.solubilityCurve1 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve1 ?? [], !1),
            solubilityCurve2: await getUpdatedFiles(simulation?.solubilityCurve2 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve2 ?? [], !1),
            solubilityCurve3: await getUpdatedFiles(simulation?.solubilityCurve3 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve3 ?? [], !1),
            solubilityCurve4: await getUpdatedFiles(simulation?.solubilityCurve4 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve4 ?? [], !1),
            solubilityCurve5: await getUpdatedFiles(simulation?.solubilityCurve5 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve5 ?? [], !1),
        }
        const res = await MaterialService.create("/simulations/save-simulations-files-data", payload);
        if (res?.status == 200) {
            //   setSimulation(res?.data?.body);
            //   resetUpload(res?.data?.body);
            result.data = res?.data?.body;
            result.status = "success";
            result.message = "Data saved.";
            return result;
        }
        result.code = 400;
        result.status = "error";
        result.message = "Oops! something went wrong.";
        return result;
    }

    const saveTakedaModel = async () => {
        let result = {
            code: 200,
            status: "success",
            message: "",
            data: {},
        }

        const {
            vendor_name,
            material_name,
            lot_number,
            temperature,
            turbidity_measured,
            model,
            solvent,
            solubility_value,
            file_path,
            // attachments,
        } = methods.control._formValues;

        const payload: any = {
            uid: auth?.claims?.uid,
            material_id: material?.item?.id ?? "",
            vendor_name,
            material_name,
            lot_number,
            model,
            y: solubility_value,
        }
        if (model === 'Model 3') {
            payload.solvent = solvent.value;
            payload.x = temperature;
            payload.file_path = file_path;
            payload.turbidity_measured = turbidity_measured;
        } else {
            payload.solvent = solvent;
            payload.x = temperature;
        }
        const res = await MaterialService.create("/simulations", payload);
        if (res?.status == 200) {
            setModel3Saved(Date.now());
            result.data = res?.data?.body;
            result.status = "success";
            result.message = "Data saved.";
            return result;
        }
        result.code = 400;
        result.status = "error";
        result.message = "Oops! something went wrong.";
        return result;
    }

    const model3APIRequest = async (file_path: string, turbidity_measured: any) => {
        let result: any = {};
        setLoading(true); // enable loading
        // const fileResp = await FileService.create("/files/download", {
        //     file: file_path,
        // });
        // setLoading(false); // disable loading
        // if (fileResp?.status === 200) {
        //      file_path = fileResp?.data.body;
        // }

        const res: any = await MaterialService.create(apiRoutes.MODEL_3_SIMULATION, { api_url: process.env.REACT_APP_API_URL, file_path, turbidity_measured });
        setLoading(false); // disable loading

        if (res?.status == 200) {
            result.solubility = res.data.solubility || 0;
            result.file_path = file_path;
            return result;
        };
        return result;
    }

    const uploadModel3Api = async (attachments: any[], turbidity_measured: any) => {
        const res = await FileService.create("/files/upload", {
            id: material?.item?.id ?? "",
            file_for: 'Material',
            files: attachments.map(
                (attachment) => ({
                    category: attachment?.category,
                    key: attachment.file?.name,
                    mimeType: attachment?.file?.type
                })),
        });

        if (res?.status === 200) {
            const filesResult = res?.data;
            return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file, attachments)))
                .then(async (res) => {
                    // setLoading(false); // disable loading
                    const solubility = await model3APIRequest(filesResult.body.files[0].file_name, turbidity_measured)

                    return {
                        solubility: solubility.solubility,
                        file_path: solubility.file_path,
                        turbidity_measured: turbidity_measured
                    };
                })
                .catch(err => {
                    console.log(err)
                    return;
                });
        }
    }

    const validateForm = async () => {
        const {
            vendor_name,
            material_name,
            lot_number,
            model,
            solvent,
            solubility_value,
            attachments,
        } = methods.control._formValues;

        if (!material.item || (!vendor_name?.length || !material_name?.length || !lot_number?.length)) {
            dispatch(alertOpenAction('Please select required fields first.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return;
        }

        if ([0].includes(tabIndex)) {
            dispatch(alertOpenAction('Please select valid screen first.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return;
        }

        if (tabIndex === 3) {
            if (!model?.length) {
                dispatch(alertOpenAction('Please select a model.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }

            if (model === 'Model 3') {
                if (!attachments?.model3?.length) {
                    dispatch(alertOpenAction('Please select Model3 file.', 'error'));
                    setTimeout(() => dispatch(alertCloseAction()));
                    return;
                }
            } else {
                if (!solvent?.length) {
                    dispatch(alertOpenAction('Please select a solvent.', 'error'));
                    setTimeout(() => dispatch(alertCloseAction()));
                    return;
                }
            }

            if (!solubility_value?.toString()?.length) {
                dispatch(alertOpenAction('Please select a solubility.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }
        }
        return true;
    }

    // method to save data
    const onSubmit = async () => {
        if (await validateForm()) {
            // const _flag = tabIndex === 1 ? true: false;
            let result = {
                code: 200,
                status: "success",
                message: "",
                data: {},
                uploadData: {},
            };
            setLoading(true); // enable loading
            let uploadedSolubilityRes: any = await saveUploadSolubility();
            if (uploadedSolubilityRes !== null && uploadedSolubilityRes !== undefined) {
                result = { ...result, ...uploadedSolubilityRes };
            }


            if (result.code === 400) {
                setLoading(false); // disable loading
                dispatch(alertOpenAction(result.message, 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }

            result = { ...result, uploadData: result.data };
            if (tabIndex === 2) {
                result = { ...result, ...await saveTakedaModel() };
                if (result.code === 200) {
                    // setSimulation(result.data);
                    await handleGraph();
                }
            }
            setLoading(false); // disable loading
            if (result.code === 200) {
                setSimulation(result.uploadData);
                resetUpload(result.uploadData);
                dispatch(alertOpenAction(`Solubility Data ${simulation ? 'updated' : 'saved'} successfully.`, 'success'));
            } else {
                dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            }
            setTimeout(() => dispatch(alertCloseAction()));

            // if (tabIndex === 1) {
            //     await saveUploadSolubility();
            // }
            // if (tabIndex === 3) {
            //     await saveTakedaModel();
            // }
        }
    };

    // Cancel button functions
    const saveMaterialSolubility = async () => {
        const { vendor_name, material_name, lot_number } = methods.control._formValues;
        if ((!vendor_name?.length || !material_name?.length || !lot_number?.length)) {
            setOpenConfirmModal(false);
            dispatch(alertOpenAction('Please fill all required fields first.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
            return;
        }
        setOpenConfirmModal(false);
        await onSubmit();
    }

    const dontSave = () => {
        history.push('/materials');
    }

    const handleClose = (path: string) => {
        if (closeFn) {
            return closeFn();
        }
        if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
            setOpenConfirmModal(true);
            return;
        }
        history.push(path);
    }

    const breadCrumbItems = [
        { label: "Home", path: "#", onClick: () => handleClose("/") },
        { label: "Apps & Models", path: "#", onClick: () => handleClose("/apps-and-models") },
        { label: "Material Solubility Models", path: "#" }
    ];

    const headerButtons: ButtonProps[] = [
        ...(hasWritePermission ? [
            {
                isIconButton: true,
                title: "Save",
                onClick: submitFn ? (e: any) => { e.preventDefault(); submitFn(); } : onSubmit,
                icon: ButtonIcons.SAVE,
                type: ButtonTypes.PRIMARY,
                disabled: loading,
                buttonType: submitFn ? ButtonElementType.BUTTON : ButtonElementType.SUBMIT
            }
        ] : []),
        {
            isIconButton: true,
            title: "Close",
            onClick: () => handleClose(`/materials`),
            icon: ButtonIcons.CROSS,
            type: ButtonTypes.SECONDARY
        },
        {
            isIconButton: true,
            title: "Help",
            onClick: () => setOpenHelpModal(true),
            icon: ButtonIcons.HELP,
            type: ButtonTypes.SECONDARY
        },
    ];

    useEffect(() => {
        if (material?.item && !Array.isArray(material?.item)) {
            if(isDirect){
            }else{
                if (material?.item?.vendor_name !== methods.control._formValues?.vendor_name) {
                    methods.setValue("material_name", ''); // clear material_name field
                    methods.setValue("lot_number", '');    // clear lot_number field
                    methods.setValue('attachments', initialData.attachments)
                }
                if (material?.item?.material_name !== methods.control._formValues?.material_name) {
                    methods.setValue("lot_number", '');    // clear lot_number field
                    methods.setValue('attachments', initialData.attachments)
                }
            }
        }
    }, [
        methods.control._formValues.vendor_name,
        methods.control._formValues.material_name,
        methods.control._formValues.lot_number,
    ]);

    useEffect(() => {
        if (simulation) resetUpload(simulation);
    }, [simulation]);

    useEffect(() => {
        methods.setValue("solvent", "");
        methods.setValue("solubility_value", "");
        methods.setValue("turbidity_measured", "");
    }, [methods.control._formValues.model]);

    useEffect(() => {
        if (methods && methods.watch) {
            const { unsubscribe } = methods.watch((data, { name, type }) => {
                if (name === "material_name" && type === "change") {
                    resetUpload({
                        ...initialData,
                        vendor_name: methods.control._formValues.vendor_name,
                        material_name: methods.control._formValues.material_name,
                        lot_number: methods.control._formValues.lot_number
                    }, { model: "" });
                }

            })
            return () => unsubscribe()
        }

    }, [methods.watch]);

    return (
        <Layout title={layoutTitles.materialSolubility} breadCrumbItems={breadCrumbItems}>
            <FormProvider {...methods}>
                <form noValidate onSubmit={(e) => { e.preventDefault(); methods.handleSubmit(onSubmit) }}>
                    <BodyHeader
                        showBackButton
                        buttons={headerButtons}
                        title={MATERIAL_SOLUBILITY_PAGE_TITLE}
                        onBackClick={() => handleClose("/apps-and-models")}
                    />
                    <div className="row">
                        <div className="col-12">
                            <CustomMaterialSearchComponent onOptionSelect={(option:any) => {
                                setIsDirect(true);
                                methods.setValue("vendor_name", option?.vendor_name || "");
                                methods.setValue("material_name", option?.material_name || "");
                                methods.setValue("lot_number", option?.lot_number || "");
                                }}
                            />
                        </div>
                    </div>
                    <SelectMaterialHeader user={user} handleSelectedFields={handleSelectedFields} isDirect={isDirect} />

                    <RenderIf condition={loading}>
                        <CustomLoader />
                    </RenderIf>

                    <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                        <div className="theme-card">
                            {/* Tab Panels */}
                            <div className="body min-h-500">
                                <TabPanel />
                                <TabPanel>
                                    <UnchainedLabs setSubmitFn={setSubmitFn} />
                                </TabPanel>
                                <TabPanel>
                                    <TakedaModels
                                        loading={loading}
                                        handleSolubility={handleSolubility}
                                        headerFilters={headerFilters}
                                        model3Saved={model3Saved}
                                        setSubmitFn={setSubmitFn}
                                        setCloseFn={setCloseFn}
                                        setCredits={setCredits}
                                    />
                                </TabPanel>
                            </div>

                            {/* Bottom buttons */}
                            <div className="head">
                                <div className="admin-tabs mb-6 " >
                                    <TabList className="inner mb-3 flex-wrap flex-col">
                                        <div className="row pt-12">
                                            {['', "Unchained Lab", "Solubility Models"].map((item, index) => (
                                                <Tab className={`col-lg-4 col-md-6 ${item === '' ? 'hidden' : ''}`} key={index} disabled={isTabDisabled}>
                                                    <div className={`theme-btn mb-6 w-full btn-md ${isTabDisabled? 'disabled' : ''}`}>{item}</div>
                                                </Tab>
                                            ))}

                                            <RenderIf condition={credits.length > 0}>
                                                <p className="col-lg-4 col-md-6 pb-6">{credits}</p>
                                            </RenderIf>
                                        </div>
                                    </TabList>
                                </div>
                            </div>
                        </div >
                    </Tabs>

                </form>
            </FormProvider>

            {/* Pop Ups */}
            <CancelConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                saveMaterial={saveMaterialSolubility}
                dontSave={dontSave}
            />
            <HelpModal
                open={openHelpModal}
                setOpen={setOpenHelpModal}
                title={MaterialSolubilityHelp.title}
                content={MaterialSolubilityHelp.content} />
        </Layout >
    );
};
export default MaterialSolubility;

const CustomMaterialSearchComponent = ({ onOptionSelect }: any) => {
    const [cachedOptions, setCachedOptions] = useState<any[]>([]);
    const [abortController, setAbortController] =
      useState<AbortController | null>(null);
    const latestQueryRef = useRef<string>("");
  
    const isValidQuery = (query: string) => {
      const trimmedQuery = query.trim();
      return trimmedQuery && trimmedQuery.length > 1;
    };
  
    const fetchOptionsThroughOpenSearch = useCallback(
      async (searchQuery: string) => {
        if (!searchQuery) {
          return cachedOptions;
        }
  
        const inputValue = searchQuery?.trim();
        if (!isValidQuery(inputValue)) {
          return [];
        }
  
        if (abortController) {
          abortController.abort();
        }
  
        const controller = new AbortController();
        setAbortController(controller);
        const { signal } = controller;
  
        latestQueryRef.current = inputValue;
  
        try {
          const response = await fetch(
            `${process.env.REACT_APP_OPEN_SEARCH_URL}/dev-material-search-index/_search`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Basic " + btoa(`${process.env.REACT_APP_OPEN_SEARCH_USERNAME}:${process.env.REACT_APP_OPEN_SEARCH_PASSWORD}`),
              },
              body: JSON.stringify({
                size: 100,
                query: {
                  bool: {
                    should: [
                      {
                        query_string: {
                          query: inputValue,
                          fields: [
                            "material_name",
                            "phase",
                            "smiles",
                            "cas_number",
                            "synonyms",
                          ],
                          default_operator: "AND",
                          allow_leading_wildcard: false,
                        },
                      },
                      {
                        query_string: {
                          query: inputValue,
                          fields: ["*"],
                          default_operator: "AND",
                        },
                      },
                    ],
                  },
                },
                sort: [
                  { "material_name.keyword": { order: "asc" } },
                  { "vendor_name.keyword": { order: "asc" } },
                  { "lot_number.keyword": { order: "asc" } },
                ],
              }),
              signal,
            }
          );
  
          const data = await response.json();
          const fetchedOptions: any[] = data?.hits?.hits || [];
  
          const formattedOptions = fetchedOptions.map((item: any) => ({
            label: `${item?._source?.material_name} - ${item?._source?.phase} - ${item?._source?.lot_number} - ${item?._source?.vendor_name}`,
            value: item?._source?.id,
            data: item?._source,
          }));
  
          setCachedOptions((prevOptions) => {
            const optionsMap = new Map();
            prevOptions.forEach((option) => optionsMap.set(option.value, option));
            formattedOptions.forEach((option) =>
              optionsMap.set(option.value, option)
            );
            return Array.from(optionsMap.values());
          });
  
          if (latestQueryRef.current === inputValue) {
            return formattedOptions || [];
          }
          return [];
        } catch (error: any) {
          if (error.name !== "AbortError") {
            console.error("Error fetching search options:", error);
          }
          return [];
        }
      },
      [cachedOptions, abortController]
    );
  
    const fetchOptionsDebounced = debounce(
      (inputValue: string, callback: any) => {
        fetchOptionsThroughOpenSearch(inputValue).then(callback);
      },
      300
    );
  
    const handleSelectChange = (selectedOption: any) => {
      if (selectedOption) {
        onOptionSelect(selectedOption?.data);
      }
    };
  
    return (
      <div className="theme-card">
        <div className="body">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="form-group">
                <label className="ip-wrap" htmlFor="vendor">
                  <span className="form-label">Advance Search</span>
                  <div className="input-wrap select-outerr">
                    <AsyncSelect
                      classNames={{
                        control: () => "theme-ip",
                      }}
                      cacheOptions={true}
                      defaultOptions={cachedOptions}
                      loadOptions={fetchOptionsDebounced}
                      onChange={handleSelectChange}
                      placeholder="Search with material name, smiles or cas"
                      isClearable
                      isSearchable
                      components={{ Option: CustomOption }}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const CustomOption = (props:any) => {
    if(!props){
      return null
    }
    if(!props.data){
      return null
    }
    const { data, innerRef, innerProps } = props;
  
    return (
      <div 
        ref={innerRef} 
        {...innerProps} 
        className="p-5 my-1 mx-0 cursor-pointer bg-white rounded shadow"
      >
        <div 
        className="flex justify-between text-base"
        >
          <div className="flex-1 text-center">{data?.data?.material_name}</div> |
          <div className="flex-1 text-center">{data?.data?.vendor_name}</div> |
          <div className="flex-1 text-center">{data?.data?.lot_number}</div> |
          <div className="flex-1 text-center">{data?.data?.phase}</div>
        </div>
      </div>
    );
  };