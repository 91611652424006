import { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import logo from '../../../assets/logo_takeda.png';
import { useHistory } from 'react-router-dom';

// types
import { LoginProps } from '../../../types';

// config
import config from '../../../utils/config';

const Login = ({ setCorsErrorModalOpen }: LoginProps) => {
  const { oktaAuth, authState } = useOktaAuth();
  const widgetRef: any = useRef();
  const history = useHistory();

  // Fetch otp and state from query params from email callback verification URI
  // Application should have http://localhost:8080/login as the email callback verification URI
  const queryParams = new URLSearchParams(window.location.search);
  const otp = queryParams?.get('otp') ?? '';
  const state = queryParams?.get('state') ?? '';
  
 useEffect(() =>{
  if(authState?.isAuthenticated){
      history.push("/")
  }
 },[authState, history])
  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCode } = config.oidc;
    const widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      issuer: issuer,
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo,
      i18n: {
        en: {
          'primaryauth.title': 'Hi, Welcome back 👋',
        },
      },
      features: {
        showPasswordToggleOnSignInPage: true,
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes,
      },
      useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
      state,
      // otp,
        customButtons: [{
            title: 'User Access Manual',
            className: 'my-plain-text-link',
            click: function() {
                // clicking on the button navigates to another page
                window.location.href = 'https://www.example.com';
            }
        }]
    });

    widget.renderEl(
        { el: widgetRef.current },
        (res: any) => {
          oktaAuth.handleLoginRedirect(res.tokens);
        },
        (err) => {
          throw err;
        },
    );

    // Note: Can't distinguish CORS error from other network errors
    const isCorsError = (err: any) => (err.name === 'AuthApiError' && !err.statusCode);

    widget.on('afterError', ({_context, error}: any) => {

      console.error("Error context:", _context);
      console.error("Error details:", error);

      if (isCorsError(error)) {
        setCorsErrorModalOpen(true);
      }
    });

    return () => widget.remove();
  }, [oktaAuth]);

  return (
      <div className='auth-sec '>
        <div className="inner-container">
          <div ref={widgetRef} />
        </div>
      </div>
  );
};

export default Login;
