import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import { useEffect } from "react";
import { setTitleNumberInput } from "../../../../../utils/common";

const DrugInformation = (props: any) => {
  const { control } = useForm<any>({ defaultValues: props.initialData });
  const methods = useFormContext();
  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "adme": {
        let displayNames = props.material?.solid?.drug_info?.attachments?.adme ?? [];
        displayNames = displayNames?.map((attachment: any) => attachment.display_name);
        const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
          if (displayNames.includes(e?.target?.files?.[key].name)) {
            return true;
          }
        });
        if (isDuplicate) {
          props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
          setTimeout(() => props.dispatch(alertCloseAction()));
          return;
        }
        methods.setValue("drug_info.attachments.adme", [
          ...(methods.getValues("drug_info.attachments.adme") || []),
          ...Object.keys(e?.target?.files ?? []).map(
            (key: any) => {
              const fileSize = e?.target?.files?.[key]?.size ?? 0;
              return {
                category: "ADME",
                file: e?.target?.files?.[key],
                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
              };
            }),
        ], { shouldDirty: true, shouldTouch: true });
      }
        break;
      case "clinical_trials": {
        let displayNames = props.material?.solid?.drug_info?.attachments?.clinical_trials ?? [];
        displayNames = displayNames?.map((attachment: any) => attachment.display_name);
        const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
          if (displayNames.includes(e?.target?.files?.[key].name)) {
            return true;
          }
        });
        if (isDuplicate) {
          props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
          setTimeout(() => props.dispatch(alertCloseAction()));
          return;
        }
        methods.setValue("drug_info.attachments.clinical_trials", [
          ...(methods.getValues("drug_info.attachments.clinical_trials") || []),
          ...Object.keys(e?.target?.files ?? []).map(
            (key: any) => {
              const fileSize = e?.target?.files?.[key]?.size ?? 0;
              return {
                category: "Clinical Trials Info",
                file: e?.target?.files?.[key],
                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
              };
            }),
        ], { shouldDirty: true, shouldTouch: true });
      }
        break;
      case "indication": {
        let displayNames = props.material?.solid?.drug_info?.attachments?.indication ?? [];
        displayNames = displayNames?.map((attachment: any) => attachment.display_name);
        const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
          if (displayNames.includes(e?.target?.files?.[key].name)) {
            return true;
          }
        });
        if (isDuplicate) {
          props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
          setTimeout(() => props.dispatch(alertCloseAction()));
          return;
        }
        methods.setValue("drug_info.attachments.indication", [
          ...(methods.getValues("drug_info.attachments.indication") || []),
          ...Object.keys(e?.target?.files ?? []).map(
            (key: any) => {
              const fileSize = e?.target?.files?.[key]?.size ?? 0;
              return {
                category: "Indication",
                file: e?.target?.files?.[key],
                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
              };
            }),
        ], { shouldDirty: true, shouldTouch: true });
      }
        break;
      case "toxicity": {
        let displayNames = props.material?.solid?.drug_info?.attachments?.toxicity ?? [];
        displayNames = displayNames?.map((attachment: any) => attachment.display_name);
        const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
          if (displayNames.includes(e?.target?.files?.[key].name)) {
            return true;
          }
        });
        if (isDuplicate) {
          props.dispatch(alertOpenAction("Same file already uploaded.", "error"));
          setTimeout(() => props.dispatch(alertCloseAction()));
          return;
        }
        methods.setValue("drug_info.attachments.toxicity", [
          ...(methods.getValues("drug_info.attachments.toxicity") || []),
          ...Object.keys(e?.target?.files ?? []).map(
            (key: any) => {
              const fileSize = e?.target?.files?.[key]?.size ?? 0;
              return {
                category: "Toxicity Data",
                file: e?.target?.files?.[key],
                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
              };
            }),
        ], { shouldDirty: true, shouldTouch: true });
      }
        break;
      default:
      // default
    }
  };

  // Remove selected file
  const removeFile = (index: number, name: string) => {
    switch (name) {
      case "adme":
        methods.setValue("drug_info.attachments.adme", (methods.getValues("drug_info.attachments.adme") || []).filter((file: any, i: number) => i !== index), {
          shouldDirty: true,
          shouldTouch: true,
        });
        break;
      case "clinical_trials":
        methods.setValue("drug_info.attachments.clinical_trials", (methods.getValues("drug_info.attachments.clinical_trials") || []).filter((file: any, i: number) => i !== index), {
          shouldDirty: true,
          shouldTouch: true,
        });
        break;
      case "indication":
        methods.setValue("drug_info.attachments.indication", (methods.getValues("drug_info.attachments.indication") || []).filter((file: any, i: number) => i !== index), {
          shouldDirty: true,
          shouldTouch: true,
        });
        break;
      case "toxicity":
        methods.setValue("drug_info.attachments.toxicity", (methods.getValues("drug_info.attachments.toxicity") || []).filter((file: any, i: number) => i !== index), {
          shouldDirty: true,
          shouldTouch: true,
        });
        break;
      default:
      // default
    }
  };

  return (
    <>
      <div className="row">

      {!props.readable || control?._formValues?.dosage_form ? (
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label
              className="ip-wrap"
              htmlFor="dosageForm"
            >
                            <span className="form-label">
                                Dosage Form
                            </span>
              <div className="input-wrap select-outer">
                {!props.readable ? (
                  <Controller
                    name="drug_info.dosage_form"
                    control={methods.control}
                    defaultValue={methods.control._formValues?.drug_info?.dosage_form ?? ""}
                    render={({ field }) =>
                      <select className="theme-ip" {...field} data-cy="s_drug_dosage_form">
                        <option value="">Select</option>
                        <option value="Tablet">Tablet</option>
                        <option value="Capsule">Capsule</option>
                        <option value="Powder">Powder</option>
                      </select>}
                  />
                ) : (
                  <p className="theme-ip pt-3 disabled">{control?._formValues?.dosage_form}</p>
                )}
              </div>
            </label>
          </div>
        </div>
      ) : null }

      {!props.readable || props?.attachments?.adme?.length > 0 || methods.control._formValues?.drug_info?.attachments?.adme?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          <MultiFileUpload
            dataCy="attachment_drug_adme"
            title="ADME"
            name="adme"
            readable={props?.readable ?? false}
            attachments={props?.attachments?.adme ?? methods.control._formValues?.drug_info?.attachments?.adme}
            handleFileChange={props?.handleFileChange ?? handleFileChange}
            removeFile={props?.removeFile ?? removeFile}
            downloadFile={props?.downloadFile}
          />
        </div>
      ) : null }

      {!props.readable || props?.attachments?.clinical_trials?.length > 0 || methods.control._formValues?.drug_info?.attachments?.clinical_trials?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          <MultiFileUpload
            title="Clinical Trials Info"
            name="clinical_trials"
            dataCy="attachment_drug_clinical_trials"
            readable={props?.readable ?? false}
            handleFileChange={props.handleFileChange ?? handleFileChange}
            removeFile={props.removeFile ?? removeFile}
            attachments={props?.attachments?.clinical_trials ?? methods.control._formValues?.drug_info?.attachments?.clinical_trials}
            downloadFile={props?.downloadFile}
          />
        </div>
      ) : null }

      {!props.readable || props?.attachments?.indication?.length > 0 || methods.control._formValues?.drug_info?.attachments?.indication?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          <MultiFileUpload
            title="Indication"
            name="indication"
            dataCy="attachment_drug_indication"
            readable={props?.readable ?? false}
            handleFileChange={props.handleFileChange ?? handleFileChange}
            removeFile={props.removeFile ?? removeFile}
            attachments={props?.attachments?.indication ?? methods.control._formValues?.drug_info?.attachments?.indication}
            downloadFile={props?.downloadFile}
          />
        </div>
      ) : null }

      {!props.readable || props?.attachments?.toxicity?.length > 0 || methods.control._formValues?.drug_info?.attachments?.toxicity?.length > 0 ? (
        <div className="col-lg-3 col-md-6">
          <MultiFileUpload
            title="Toxicity Data"
            name="toxicity"
            dataCy="attachment_drug_toxicity"
            readable={props?.readable ?? false}
            handleFileChange={props.handleFileChange ?? handleFileChange}
            removeFile={props.removeFile ?? removeFile}
            attachments={props?.attachments?.toxicity ?? methods.control._formValues?.drug_info?.attachments?.toxicity}
            downloadFile={props?.downloadFile}
          />
        </div>
      ) : null }

      </div>
    </>
  );
};

export default DrugInformation;